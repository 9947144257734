import React, { Component } from 'react';

import '../css/Landing.css';




import TitleCard from '../assets/landing/Title-Card.gif';

import LogoAsset from '../assets/landing/logo.png';

import WhitepaperPDF from '../assets/landing/CatNIPWhitepaper.pdf';
import BlockAuditCatNIPPDF from '../assets/landing/BlockAuditCatNIP.pdf';

import Card1 from '../assets/landing/card-1.png';
import Card2 from '../assets/landing/card-2.png';
import Card3 from '../assets/landing/card-3.png';

import BlockAuditPNG from '../assets/landing/block-audit.jpeg';

import PlayCatPNG from '../assets/landing/play-cat.png';

import LootexLogo from '../assets/landing/lootex.png';




class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = { 

            isMobileMenuCollapsed: false,
        };

        this.ToggleMobileMenu = this.ToggleMobileMenu.bind(this);

    }

    ToggleMobileMenu() {
        this.setState({
            isMobileMenuCollapsed: !this.state.isMobileMenuCollapsed
        })
      }

    render() {
        return (
            <body>
                <section id='navbar'>

                    <nav class="navbar navbar-expand-lg navbar-dark">


                        <a class="navbar-brand" href="/home">
                            <img class='logo' src={LogoAsset} />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" onClick={this.ToggleMobileMenu}>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse"  className={this.state.isMobileMenuCollapsed ? 'navbar-collapse' : 'collapse navbar-collapse'} id="navbarNavAltMarkup">
                            <div class="navbar-nav ms-auto d-flex align-items-center">
                                <a class="nav-link" href="#about-us">About Us</a>
                                <a class="nav-link" href="#tokenomics">Tokenomics</a>
                                <a class="nav-link" href="#audits">Audits</a>
                                <a class="nav-link" href={WhitepaperPDF}>White Paper</a>
                                <a class="nav-link play-now-button" href="https://catnip.world/game"><button class=" btn btn-warning">Play Now</button></a>
                            </div>
                        </div>


                    </nav>


                </section>

                <section id="title">
                    <div class='container-fluid'>


                        <img class='title-image' src={TitleCard} />


                    </div>
                </section>

                <section id="about-us">
                    <div class='container-fluid'>

                        <div class="row">
                            <div class="col-lg-6 about-us-content container-fluid">
                                <h1>About CatNIP Battler</h1>
                                <p>CatNIP Battler is a cat collection and battle game.
                                    Players pick a team of 3 cats and go for a battle with other cats in different regions.
                                    <br /><br />
                                    Each battle will spawn 3 random rarity cats within the region's pool.
                                    <br /><br />
                                    At the end of battle, players will be able to choose 1 of the cats and mint it as an NFT to add into their collection that can be used for future battles.</p>


                                <iframe clwidth="440" height="220" src="https://www.youtube.com/embed/uAQQfSardZ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


                            </div>

                            <div class="col-lg-6 about-us-image container-fluid">
                                <img class='card-1' src={Card1} />
                                <img class='card-2' src={Card2} />
                                <img class='card-3' src={Card3} />
                            </div>
                        </div>
                    </div>

                </section>

                <section id="tokenomics">

                    <div class="container-fluid">
                        <h1 class='tokenomics-title'>Tokenomics</h1>
                        <br />
                        <br />
                        <div class="row row-background">

                            <div class="col-lg-4">
                                <div class="card" >
                                    <div class="card-body">
                                        <h5 class="card-title">Fixed Supply</h5>

                                        <p class="card-text">No Staking or Farming mechanism which allows for more NIP to be created<br /><br /></p>

                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Taxation</h5>

                                        <p class="card-text">
                                            2% to liquidity
                                            <br />
                                            2% to holders
                                            <br />
                                            1% to team wallet
                                            <br /><br />
                                        </p>

                                    </div>
                                </div>

                            </div>


                            <div class="col-lg-4">
                                <div class="card" >
                                    <div class="card-body">
                                        <h5 class="card-title">Anti Whale</h5>

                                        <p class="card-text">Max transfer, buy, or sell amount per day is 1,000,000 NIP. Max transfer, but with a 24 hours cooldown</p>

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                </section>

                <section id="audits">
                    <div class="container-fluid">
                        <h1 class="audit-title">Audits</h1>
                        <br />
                        <img class="audit-cert" src={BlockAuditPNG} />
                        <br /><br />
                        <a href={BlockAuditCatNIPPDF}><h3>Download Reports Here</h3></a>
                    </div>
                </section>

                <section id="partner">
                    <div class="container-fluid">
                        <h1 class="partner-title">Trade Your NFTs At</h1>
                        <br/>
                            <a href="https://lootex.io/stores/catnip-world"><img class="partner-logo" src={LootexLogo}/></a>
   

                    </div>
                </section>

                <section id="play-now">
                    <div class='container-fluid '>
                        <div class='row d-flex align-items-center'>
                            <div class='col-sm-5 text-end'>
                                <img class="play-button-cat" src={PlayCatPNG} />
                            </div>
                            <div class='col-sm-7 text-start button-col'>
                                <a href="https://catnip.world/game"><button class="play-button btn btn-warning btn-lg">Play Now</button></a>
                            </div>
                        </div>
                    </div>


                </section>

                <footer id="contact">
                    <div class="container-fluid">
                        <a href="https://discord.gg/wDqrC88QJW"><i class="social-icon fab fa-discord fa-2x"></i></a>
                        <a href="https://twitter.com/CatnipToken"><i class="social-icon fab fa-twitter fa-2x"></i></a>
                        <a href="https://www.instagram.com/catniptoken/"><i class="social-icon fab fa-instagram fa-2x"></i></a>
                        <a href="https://t.me/CatNIPen"><i class="social-icon fab fa-telegram fa-2x" ></i></a>
                        <a href="https://www.youtube.com/channel/UC91NYw0Dsan-ecW3t24CNrQ/featured"><i class="social-icon fab fa-youtube fa-2x"></i></a>
                        <p>© Copyright 2021 CatNIP</p>
                    </div>
                </footer>
            </body>


        );
    }
}

export default Landing;
