import React from 'react';

class UnityGame extends React.Component {
    render() {

        // return <iframe frameborder="0" src="https://itch.io/embed-upload/4403103?color=333333" allowfullscreen="" width="1280" height="740"><a href="https://catnip-world.itch.io/catnip-battler">Play CatNIP Battler on itch.io</a></iframe>
        // return <iframe frameborder="0" src="https://catnipworld.github.io/Mainnet/" allowfullscreen="" width="1400" height="800"><a href="https://catnipworld.github.io/Mainnet/">Play CatNIP Battler on Github</a></iframe>
        return <iframe frameborder="0" src="https://itch.io/embed-upload/4466059?color=333333" allowfullscreen="" width="1280" height="800"><a href="https://catnip-world.itch.io/catnip-battler">Play CatNIP Battler on itch.io</a></iframe>
    }
}
export default UnityGame
