import React from 'react';

class UnityGame extends React.Component {
    render() {

        // return <iframe frameborder="0" src="https://itch.io/embed-upload/4403131?color=333333" allowfullscreen="" width="1280" height="740"><a href="https://catnip-world.itch.io/catnipbattler-testnet">Play CatNIP Battler TestNet on itch.io</a></iframe>
        // return <iframe frameborder="0" src="https://catnipworld.github.io/Testnet/" allowfullscreen="" width="1400" height="800"><a href="https://catnipworld.github.io/Testnet/">Play CatNIP Battler TestNet on Github</a></iframe>
        return <iframe frameborder="0" src="https://itch.io/embed-upload/4462552?color=333333" allowfullscreen="" width="1280" height="800"><a href="https://catnip-world.itch.io/catnipbattler-testnet">Play TestNet CatNIP Battler TestNet on itch.io</a></iframe>
    }
}
export default UnityGame
